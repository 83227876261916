import React from 'react'
import { NavLink } from 'react-router-dom'
import css from './Layout.module.css'

function MobileNavPopup({ isOpen, onClose, user }) {
   return (
      <div className={`${css.mobileNavPopup} ${isOpen ? css.open : ''}`}>
         <span className={css.closeButton} onClick={onClose}>
            &times;
         </span>
         <nav>
            {user?.role && user.role !== 'visitor' && (
               <>
                  {user.role === 'CreatorUser' && (
                     <NavLink
                        to="/dashboard/creator"
                        className={css.tab}
                        onClick={onClose}
                     >
                        Dashboard Creator
                     </NavLink>
                  )}
                  {user.role === 'RegularUser' && (
                     <NavLink
                        to="/dashboard/regular"
                        className={css.tab}
                        onClick={onClose}
                     >
                        Dashboard Regular
                     </NavLink>
                  )}
                  {user.role === 'AdminUser' && (
                     <NavLink
                        to="/dashboard/admin"
                        className={css.tab}
                        onClick={onClose}
                     >
                        Dashboard Admin
                     </NavLink>
                  )}
               </>
            )}

            <NavLink to="/home" className={css.tab} onClick={onClose}>
               Página Inicial
            </NavLink>
            <NavLink
               to="/criadoresfinanceiros"
               className={css.tab}
               onClick={onClose}
            >
               Educadores
            </NavLink>
            <NavLink
               to="/eventosparcerias"
               className={css.tab}
               onClick={onClose}
            >
               Eventos de Parcerias
            </NavLink>
            {user?.role !== 'CreatorUser' && (
               <NavLink
                  to="/ferramentas/investingTools/etf"
                  className={css.tab}
                  onClick={onClose}
               >
                  Ferramentas
               </NavLink>
            )}
            <NavLink to="/glossario" className={css.tab} onClick={onClose}>
               Glossário
            </NavLink>
            <NavLink to="/noticias" className={css.tab} onClick={onClose}>
               Notícias
            </NavLink>
            <NavLink to="/eventos" className={css.tab} onClick={onClose}>
               Corretoras
            </NavLink>
            <NavLink to="/brokers" className={css.tab} onClick={onClose}>
               Corretoras
            </NavLink>
            <NavLink to="/cursos" className={css.tab} onClick={onClose}>
               Cursos e Formações
            </NavLink>
            <NavLink to="/website" className={css.tab} onClick={onClose}>
               Website e Apps
            </NavLink>
            <NavLink to="/community" className={css.tab} onClick={onClose}>
               Comunidades
            </NavLink>
            <NavLink to="/podcasts" className={css.tab} onClick={onClose}>
               Podcasts
            </NavLink>
            <NavLink to="/livros" className={css.tab} onClick={onClose}>
               Literacia Financeira
            </NavLink>
         </nav>
      </div>
   )
}

export default MobileNavPopup
