import React, { useEffect, useState } from 'react'
import { ClipLoader } from 'react-spinners' // Importa o spinner
import styles from './Noticias.module.css' // Importa o CSS Modules
import { Button } from 'primereact/button'

const predefinedTerms = [
   { label: 'Google News', value: 'finanças', endpoint: '/noticias' },
   { label: 'Investimentos', value: 'investimentos', endpoint: '/noticias' },
   { label: 'Imobiliário', value: 'Imobiliário', endpoint: '/noticias' },
   { label: 'Ações', value: 'ações', endpoint: '/noticias' },
   { label: 'ETFs', value: 'etf', endpoint: '/noticias' },
   { label: 'CNBC', value: 'cnbc', endpoint: '/noticias/cnbc' },
   { label: 'Investing', value: 'investing', endpoint: '/noticias/investing' },
   {
      label: 'Financial Times',
      value: 'financialtimes',
      endpoint: '/noticias/financialtimes',
   },
   { label: 'Yahoo Finance', value: 'yahoo', endpoint: '/noticias/yahoo' },
   {
      label: 'MarketWatch',
      value: 'marketwatch',
      endpoint: '/noticias/marketwatch',
   },
]

const Noticias = () => {
   const [news, setNews] = useState([])
   const [loading, setLoading] = useState(true)
   const [error, setError] = useState(null)
   const [searchTerm, setSearchTerm] = useState(predefinedTerms[0].value)
   const [currentEndpoint, setCurrentEndpoint] = useState(
      predefinedTerms[0].endpoint
   )

   const fetchNews = async (endpoint, query) => {
      setLoading(true)
      setError(null)

      try {
         const response = await fetch(
            `${process.env.REACT_APP_API_URL}${endpoint}?q=${encodeURIComponent(
               query
            )}`
         )

         if (!response.ok) {
            throw new Error(`Erro: ${response.status} - ${response.statusText}`)
         }

         const data = await response.json()
         setNews(data)
      } catch (error) {
         console.error('Erro ao buscar notícias:', error)
         setError(
            'Erro ao carregar notícias. Por favor, tenta novamente mais tarde.'
         )
      } finally {
         setLoading(false)
      }
   }

   useEffect(() => {
      fetchNews(currentEndpoint, searchTerm)
   }, [searchTerm, currentEndpoint])

   const handleTabChange = (value, endpoint) => {
      setSearchTerm(value)
      setCurrentEndpoint(endpoint)
   }

   return (
      <div className={styles.container}>
         <h2 className={styles.titulos} style={{ marginTop: '30px' }}>
            Últimas Notícias de Finanças
         </h2>

         <nav className={styles.tabNavigation}>
            {predefinedTerms.map((term) => (
               <Button
                  key={term.value}
                  className={`${styles['topic-tab-button']} ${
                     searchTerm === term.value ? styles.gradientButton : ''
                  }`}
                  onClick={() => handleTabChange(term.value, term.endpoint)}
               >
                  {term.label}
               </Button>
            ))}
         </nav>

         {loading && (
            <div className={styles['spinner-container']}>
               <ClipLoader size={50} color="#e7bc2f" />
               <p>A carregar notícias...</p>
            </div>
         )}

         {error && <p className={styles['error-message']}>{error}</p>}

         {!loading && !error && news.length > 0 ? (
            <div className={styles['news-grid']}>
               {news.slice(0, 16).map((item, index) => (
                  <div key={index} className={styles['news-card']}>
                     <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles['news-link']}
                     >
                        {item.imageUrl ? (
                           <img
                              src={item.imageUrl}
                              alt={item.title}
                              className={styles['news-card-image']}
                           />
                        ) : (
                           <div className={styles['news-card-placeholder']}>
                              Sem imagem disponível
                           </div>
                        )}
                        <div className={styles['news-card-content']}>
                           <h3 className={styles['news-card-title']}>
                              {item.title}
                           </h3>
                           <p className={styles['news-card-description']}>
                              {item.contentSnippet ||
                                 'Sem descrição disponível.'}
                           </p>
                           <p className={styles['news-card-date']}>
                              {new Date(item.pubDate).toLocaleDateString(
                                 'pt-PT',
                                 {
                                    day: '2-digit',
                                    month: 'long',
                                    year: 'numeric',
                                 }
                              )}
                           </p>
                           <p className={styles['news-card-source']}>
                              Fonte: {item.source || 'Desconhecida'}
                           </p>
                        </div>
                     </a>
                  </div>
               ))}
            </div>
         ) : (
            !loading &&
            !error && (
               <p className={styles['no-news-message']}>
                  Nenhuma notícia encontrada.
               </p>
            )
         )}
      </div>
   )
}

export default Noticias
