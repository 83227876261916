import './App.css'
import React, { Suspense } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import 'primereact/resources/themes/lara-light-indigo/theme.css' // theme
import 'primereact/resources/primereact.css' // core css
import 'primeicons/primeicons.css' // icons
import 'primeflex/primeflex.css' // css utility
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import { UserContext } from './context/useUserContext'
import Layout from './pages/Layout'
import SubscriptionCard from './pages/dashboardUsers/dashBoardRegular/dashBoardRegularComponents/userProfileData/SubscriptionCard'
import EditableUserInfoCard from './pages/dashboardUsers/dashBoardRegular/dashBoardRegularComponents/userProfileData/EditableUserInfoCard'

import ContentManagement from './pages/dashboardUsers/dashBoardCreator/ContentManagement'
import InteractionEngagement from './pages/dashboardUsers/dashBoardCreator/InteractionEngagement'
import { DashboardProvider } from './context/DashboardContext'
import ArticlesManagement from './pages/dashboardUsers/dashBoardAdmin/components/ContentManagementComponents/ArticlesManagement'
import CourseManagement from './pages/dashboardUsers/dashBoardCreator/contentManagementComponents/courseManagement/CourseManagement'
import EventsTabPanel from './pages/dashboardUsers/dashBoardCreator/contentManagementComponents/eventManagement/EventsTabPanel'
import WelcomeVideoSection from './pages/dashboardUsers/dashBoardCreator/contentManagementComponents/welcomeVideoManagement/WelcomeVideoSection'
import AnnouncementManager from './pages/dashboardUsers/dashBoardCreator/contentManagementComponents/announcementManagement/AnnouncementManager'
import PrivateRoute from './components/protected/PrivateRoute'
import ArticleManagement from './pages/dashboardUsers/dashBoardCreator/contentManagementComponents/articleManagement/ArticleManagement'
import FileManagement from './pages/dashboardUsers/dashBoardCreator/contentManagementComponents/fileManagement/FileManagement'
import BigEventsDashboard from './pages/dashboardUsers/dashBoardCreator/contentManagementComponents/eventManagement/CreatorBigEvents/BigEventsDashBoad'
import EventCreationForm from './pages/dashboardUsers/dashBoardCreator/contentManagementComponents/eventManagement/eventsCreator/EventCreationForm'
import EventManagementPage from './pages/dashboardUsers/dashBoardCreator/contentManagementComponents/eventManagement/eventsCreator/EventManagementPage'
import ToolTypes from './components/financeTools/ToolTypes'
import InvestingTools from './components/financeTools/TypesOfTools/InvestingTools'
import PersonalFinanceTools from './components/financeTools/TypesOfTools/PersonalFinanceTools'
import NotificationSettings from './pages/dashboardUsers/dashBoardRegular/dashBoardRegularComponents/notificationsComponents/NotificationSettings'
import NotificacoesCard from './pages/dashboardUsers/dashBoardRegular/dashBoardRegularComponents/notificationsComponents/NotificacoesCard'
import FavoriteTopicsCard from './pages/dashboardUsers/dashBoardRegular/dashBoardRegularComponents/favorites/FavoriteTopicsCard'
import FinancialHealthDashboard from './pages/dashboardUsers/dashBoardRegular/dashBoardRegularComponents/financialHealth/FinancialHealthDashboard'
import FinancialHealthForm from './pages/dashboardUsers/dashBoardRegular/dashBoardRegularComponents/financialHealth/financialHealthComponents/FinancialHealthForm'
import IncomeForm from './pages/dashboardUsers/dashBoardRegular/dashBoardRegularComponents/financialHealth/financialHealthComponents/FinancialHealthFormComponents/IncomeForm'
import ExpensesForm from './pages/dashboardUsers/dashBoardRegular/dashBoardRegularComponents/financialHealth/financialHealthComponents/FinancialHealthFormComponents/ExpensesForm'
import DebtsForm from './pages/dashboardUsers/dashBoardRegular/dashBoardRegularComponents/financialHealth/financialHealthComponents/FinancialHealthFormComponents/DebtsForm'
import SavingsForm from './pages/dashboardUsers/dashBoardRegular/dashBoardRegularComponents/financialHealth/financialHealthComponents/FinancialHealthFormComponents/SavingsForm'
import GoalsForm from './pages/dashboardUsers/dashBoardRegular/dashBoardRegularComponents/financialHealth/financialHealthComponents/GoalsForm'
import FinancialHealthNav from './pages/dashboardUsers/dashBoardRegular/dashBoardRegularComponents/financialHealth/financialHealthComponents/FinancialHealthFormComponents/FinancialHealthNav'
import FireManager from './pages/dashboardUsers/dashBoardRegular/dashBoardRegularComponents/financialHealth/financialHealthComponents/fireComponents/FireManager'
import FireAcc from './pages/dashboardUsers/dashBoardRegular/dashBoardRegularComponents/financialHealth/financialHealthComponents/fireComponents/FireAcc'
import FireDist from './pages/dashboardUsers/dashBoardRegular/dashBoardRegularComponents/financialHealth/financialHealthComponents/fireComponents/FireDist'
import FireGraphs from './pages/dashboardUsers/dashBoardRegular/dashBoardRegularComponents/financialHealth/financialHealthComponents/fireComponents/FireGraphs'
import FireLength from './pages/dashboardUsers/dashBoardRegular/dashBoardRegularComponents/financialHealth/financialHealthComponents/fireComponents/FireLength'
import InvestimentosNav from './pages/dashboardUsers/dashBoardRegular/dashBoardRegularComponents/financialHealth/financialHealthComponents/investimentosComponents/InvestimentosNav'
import Investimentos from './pages/dashboardUsers/dashBoardRegular/dashBoardRegularComponents/financialHealth/financialHealthComponents/investimentosComponents/Investimentos'
import Portfolio from './pages/dashboardUsers/dashBoardRegular/dashBoardRegularComponents/financialHealth/financialHealthComponents/investimentosComponents/Portfolio'
import ResumoOrcamento from './pages/dashboardUsers/dashBoardRegular/dashBoardRegularComponents/financialHealth/financialHealthComponents/FinancialHealthFormComponents/ResumoOrcamento'
import CategoryManager from './pages/dashboardUsers/dashBoardRegular/dashBoardRegularComponents/financialHealth/financialHealthComponents/FinancialHealthFormComponents/components/savingsFormComponents/CategoryManager'
import SavingsGoalsManager from './pages/dashboardUsers/dashBoardRegular/dashBoardRegularComponents/financialHealth/financialHealthComponents/FinancialHealthFormComponents/components/savingsFormComponents/SavingsGoalsManager'
import DebtCategoryManager from './pages/dashboardUsers/dashBoardRegular/dashBoardRegularComponents/financialHealth/financialHealthComponents/FinancialHealthFormComponents/components/debtsFormComponents/DebtCategoryManager'
import DebtStatistics from './pages/dashboardUsers/dashBoardRegular/dashBoardRegularComponents/financialHealth/financialHealthComponents/FinancialHealthFormComponents/components/debtsFormComponents/DebtStatistics'
import ExpensesCategoryManager from './pages/dashboardUsers/dashBoardRegular/dashBoardRegularComponents/financialHealth/financialHealthComponents/FinancialHealthFormComponents/components/expensesFormComponents/ExpensesCategoryManager'
import ExpensesSummary from './pages/dashboardUsers/dashBoardRegular/dashBoardRegularComponents/financialHealth/financialHealthComponents/FinancialHealthFormComponents/components/expensesFormComponents/ExpensesSummary'
import WhatIfScenarios from './pages/dashboardUsers/dashBoardRegular/dashBoardRegularComponents/financialHealth/financialHealthComponents/FinancialHealthFormComponents/components/expensesFormComponents/WhatIfScenarios'
import ExpensesCharts from './pages/dashboardUsers/dashBoardRegular/dashBoardRegularComponents/financialHealth/financialHealthComponents/FinancialHealthFormComponents/components/expensesFormComponents/ExpensesCharts'
import DebtDestroyer from './pages/dashboardUsers/dashBoardRegular/dashBoardRegularComponents/financialHealth/financialHealthComponents/FinancialHealthFormComponents/components/debtsFormComponents/DebtDestroyer'
import Playlist from './pages/dashboardUsers/dashBoardCreator/contentManagementComponents/playlistManagement/Playlist'
import PlaylistForm from './pages/dashboardUsers/dashBoardCreator/contentManagementComponents/playlistManagement/playlistsComponents/PlaylistForm'
import PlaylistManager from './pages/dashboardUsers/dashBoardCreator/contentManagementComponents/playlistManagement/playlistsComponents/PlaylistManager'
import UserPlaylistsList from './pages/dashboardUsers/dashBoardCreator/contentManagementComponents/playlistManagement/playlistsComponents/UserPlaylistsList'
import CreatorInfo from './pages/dashboardUsers/dashBoardCreator/perfil/CreatorInfo'
import PodcastsPage from './pages/podcasts/PodcastsPage'
import CommunitiesPage from './pages/communities/CommunitiesPage'
import WebsitesPage from './pages/websites/websitePages/WebsitesPage'
import BrokersPage from './pages/brokers/brokersPage/BrokersPage'
import EventsPage from './pages/conteudos/eventos/eventosPage/EventsPage'
import Noticias from './pages/Noticias'

//core

const HomeScreen = React.lazy(() => import('./pages/HomeScreen'))
const Glossary = React.lazy(() => import('./pages/Glossary'))
const Livros = React.lazy(() => import('./components/books/Livros'))
const Patrocinios = React.lazy(() => import('./pages/Patrocinios'))
const AvaliarETF = React.lazy(() =>
   import(
      './components/financeTools/TypesOfTools/InvestingToolsComponents/AnalysisETF/AvaliarETF'
   )
)
const AvaliarREITS = React.lazy(() =>
   import(
      './components/financeTools/TypesOfTools/InvestingToolsComponents/AnalysisREITs/reitsValorIntrinseco/AvaliarREITS'
   )
)

const CompoundInterest = React.lazy(() =>
   import(
      './components/financeTools/TypesOfTools/PersonalFinanceToolsComponents/compoundInterest/CompoundInterest'
   )
)

const Aboutus = React.lazy(() => import('./pages/Aboutus'))
const DynamicContent = React.lazy(() =>
   import('./pages/dynamic/DynamicContent')
)
const LoginModal = React.lazy(() => import('./pages/login/Login'))
const RegistrationFormRUsers = React.lazy(() =>
   import('./pages/createUsers/RegistrationFormRUsers')
)
const RegistrationFormCreators = React.lazy(() =>
   import('./pages/createUsers/RegistrationFormCreators')
)
const DashboardRegular = React.lazy(() =>
   import('./pages/dashboardUsers/DashboardRegular')
)
const DashboardCreators = React.lazy(() =>
   import('./pages/dashboardUsers/DashboardCreators')
)
const CoursesPage = React.lazy(() => import('./pages/cursos/CoursesPage'))
const ArticlesPage = React.lazy(() => import('./pages/articles/ArticlesPage'))
const CreatorsFin = React.lazy(() => import('./pages/criadores/CreatorsFin'))
const TypeUser = React.lazy(() => import('./pages/createUsers/TypeUser'))
const RecoverPassword = React.lazy(() =>
   import('./pages/login/RecoverPassword')
)
const CreatorsPages = React.lazy(() =>
   import('./pages/creatorsPage/CreatorsPages')
)
const AdminDBManagement = React.lazy(() =>
   import('./pages/dashboardUsers/dashBoardAdmin/AdminDBManagement')
)
const PartenerEvents = React.lazy(() =>
   import('./pages/partenerEvents/PartenerEvents')
)
const EventDetails = React.lazy(() =>
   import('./pages/partenerEvents/EventDetails')
)

function App() {
   const { user, loading } = React.useContext(UserContext)

   if (loading) {
      return <div>Loading...</div> // Mostre um carregamento enquanto valida a sessão
   }

   return (
      <>
         <DashboardProvider userData={user || { role: 'visitor' }}>
            <div className="app">
               <Suspense fallback={<div>Loading...</div>}>
                  <Routes>
                     <Route path="/escolherutilizador" element={<TypeUser />} />
                     <Route
                        path="/contacriadores"
                        element={<RegistrationFormCreators />}
                     />
                     <Route
                        path="/registar"
                        element={<RegistrationFormRUsers />}
                     />
                     <Route
                        path="/recover-password"
                        element={<RecoverPassword />}
                     />
                     <Route path="/login" element={<LoginModal />} />
                     <Route path="/" element={<Layout />}>
                        {/* Redirecionamento da raiz para /home */}
                        <Route
                           index
                           element={<Navigate replace to="/home" />}
                        />

                        {/* <Route index element={<HomeScreen />} /> */}
                        <Route path="/home" element={<HomeScreen />} />
                        <Route
                           path="/criadoresfinanceiros"
                           element={<CreatorsFin />}
                        />
                        <Route
                           path="/eventosparcerias"
                           element={<PartenerEvents />}
                        />
                        <Route path="/glossario" element={<Glossary />} />
                        <Route path="/livros" element={<Livros />} />
                        <Route
                           path="announcements"
                           element={<AnnouncementManager />}
                        />
                        <Route
                           path="welcome-video"
                           element={<WelcomeVideoSection />}
                        />
                        <Route path="courses" element={<CourseManagement />} />
                        <Route
                           path="articles"
                           element={<ArticlesManagement />}
                        />
                        <Route path="events" element={<EventsTabPanel />} />
                        <Route path="/patrocinios" element={<Patrocinios />} />
                        <Route path="ferramentas" element={<ToolTypes />}>
                           <Route
                              path="investingTools"
                              element={<InvestingTools />}
                           >
                              <Route path="etf" element={<AvaliarETF />} />
                              <Route path="reits" element={<AvaliarREITS />} />
                           </Route>
                           <Route
                              path="personalFinanceTools"
                              element={<PersonalFinanceTools />}
                           >
                              <Route
                                 path="juroscompostos"
                                 element={<CompoundInterest />}
                              />
                           </Route>
                        </Route>
                        <Route path="/aboutus" element={<Aboutus />} />
                        <Route
                           path="/dynamic-content/:topic"
                           element={<DynamicContent />}
                        />
                        <Route path="/brokers" element={<BrokersPage />} />
                        <Route path="/cursos" element={<CoursesPage />} />
                        <Route path="/noticias" element={<Noticias />} />
                        <Route path="/website" element={<WebsitesPage />} />
                        <Route path="/eventos" element={<EventsPage />} />
                        <Route path="/podcasts" element={<PodcastsPage />} />
                        <Route
                           path="/community"
                           element={<CommunitiesPage />}
                        />
                        <Route path="/artigos" element={<ArticlesPage />} />
                        <Route
                           path="/creators/:username"
                           element={<CreatorsPages />}
                        />
                        <Route
                           path="/events/:slug"
                           element={<EventDetails />}
                        />
                        <Route
                           path="*"
                           element={<Navigate replace to="/home" />}
                        />
                     </Route>
                     <Route element={<PrivateRoute />}>
                        <Route
                           path="dashboard/creator"
                           element={<DashboardCreators />}
                        >
                           <Route path="info" element={<CreatorInfo />} />
                           <Route
                              path="content-management"
                              element={<ContentManagement />}
                           >
                              <Route
                                 path="announcements"
                                 element={<AnnouncementManager />}
                              />
                              <Route
                                 path="welcome-video"
                                 element={<WelcomeVideoSection />}
                              />
                              <Route
                                 path="courses"
                                 element={<CourseManagement />}
                              />
                              <Route
                                 path="articles"
                                 element={<ArticleManagement />}
                              />
                              <Route
                                 path="files"
                                 element={<FileManagement />}
                              />
                              <Route path="events" element={<EventsTabPanel />}>
                                 <Route
                                    path="big-events-dashboard"
                                    element={<BigEventsDashboard />}
                                 />
                                 <Route
                                    path="create-event"
                                    element={<EventCreationForm />}
                                 />
                                 <Route
                                    path="my-events-dashboard"
                                    element={<EventManagementPage />}
                                 />
                              </Route>
                              <Route path="playlists" element={<Playlist />}>
                                 <Route
                                    path="create"
                                    element={<PlaylistForm />}
                                 />
                                 <Route
                                    path="manage"
                                    element={<PlaylistManager />}
                                 />
                                 <Route
                                    path="myPlaylists"
                                    element={<UserPlaylistsList />}
                                 />
                              </Route>
                           </Route>
                           <Route
                              path="interaction-engagement"
                              element={<InteractionEngagement />}
                           />
                        </Route>

                        <Route
                           path="/dashboard/AdminUser"
                           element={<AdminDBManagement />}
                        />

                        <Route
                           path="/dashboard/regular"
                           element={<DashboardRegular />}
                        >
                           <Route
                              path="favorites"
                              element={<FavoriteTopicsCard />}
                           />
                           <Route
                              path="notificacoes"
                              element={<NotificacoesCard />}
                           />
                           <Route
                              path="edit"
                              element={<EditableUserInfoCard />}
                           />
                           <Route
                              path="financialHealth"
                              element={<FinancialHealthDashboard />}
                           >
                              <Route
                                 path="financialHealthForm"
                                 element={<FinancialHealthForm />}
                              />
                              <Route path="fire" element={<FireManager />}>
                                 <Route
                                    path="anosFire"
                                    element={<FireLength />}
                                 />
                                 <Route
                                    path="fire-regra-4"
                                    element={<FireAcc />}
                                 />
                                 <Route
                                    path="fire-dividendos"
                                    element={<FireDist />}
                                 />
                                 <Route
                                    path="fire-graphs"
                                    element={<FireGraphs />}
                                 />
                              </Route>
                              <Route
                                 path="budget"
                                 element={<FinancialHealthNav />}
                              >
                                 {/* Redirect to resumoOrcamento if user visits /budget */}
                                 <Route
                                    path=""
                                    element={
                                       <Navigate to="resumoOrcamento" replace />
                                    }
                                 />
                                 <Route
                                    path="resumoOrcamento"
                                    element={<ResumoOrcamento />}
                                 />
                                 <Route
                                    path="income"
                                    element={<IncomeForm />}
                                 />
                                 <Route
                                    path="expenses"
                                    element={<ExpensesForm />}
                                 />
                                 <Route path="debts" element={<DebtsForm />} />
                                 <Route
                                    path="debts/categories"
                                    element={<DebtCategoryManager />}
                                 />
                                 <Route
                                    path="debts/statistics"
                                    element={<DebtStatistics />}
                                 />
                                 <Route
                                    path="debts/aniquiladordedividas"
                                    element={<DebtDestroyer />}
                                 />

                                 <Route
                                    path="savings"
                                    element={<SavingsForm />}
                                 />

                                 {/* <Route
                                       path="insurances"
                                       element={<InsurancesForm />}
                                    />
                                    <Route
                                       path="insurances/categories"
                                       element={<InsuranceCategoryManager />}
                                    />
                                    <Route
                                       path="insurances/objetivos"
                                       element={<InsuranceObjectivesForm />}
                                    />
                                    <Route
                                       path="income/categories"
                                       element={<IncomeCategoryManager />}
                                    /> */}
                                 <Route
                                    path="savings/categories"
                                    element={<CategoryManager />}
                                 />
                                 <Route
                                    path="/dashboard/regular/financialHealth/budget/expenses"
                                    element={<ExpensesForm />}
                                 />
                                 <Route
                                    path="/dashboard/regular/financialHealth/budget/expenses/categories"
                                    element={<ExpensesCategoryManager />}
                                 />
                                 <Route
                                    path="/dashboard/regular/financialHealth/budget/expenses/summary"
                                    element={<ExpensesSummary />}
                                 />

                                 <Route
                                    path="/dashboard/regular/financialHealth/budget/expenses/visualizarDespesas"
                                    element={<ExpensesCharts />}
                                 />
                                 <Route
                                    path="/dashboard/regular/financialHealth/budget/expenses/simuladorDespesas"
                                    element={<WhatIfScenarios />}
                                 />

                                 <Route
                                    path="/dashboard/regular/financialHealth/budget/savings/goals"
                                    element={<SavingsGoalsManager />}
                                 />
                              </Route>
                              <Route
                                 path="investimentos"
                                 element={<InvestimentosNav />}
                              >
                                 <Route
                                    path="investments"
                                    element={<Investimentos />}
                                 />
                                 <Route
                                    path="portfolio"
                                    element={<Portfolio />}
                                 />
                              </Route>
                              <Route path="goals" element={<GoalsForm />} />
                           </Route>

                           <Route
                              path="subscriptions"
                              element={<SubscriptionCard />}
                           />
                           <Route
                              path="notifications"
                              element={<NotificationSettings />}
                           />
                        </Route>
                     </Route>
                     <Route
                        path="*"
                        element={<Navigate replace to="/home" />}
                     />
                  </Routes>
               </Suspense>
            </div>
         </DashboardProvider>
      </>
   )
}

export default App
