import React, { useState, useContext } from 'react'
import { Outlet, NavLink, useLocation } from 'react-router-dom'
import Navbar from '../components/navbar/Navbar'
import Banner from '../components/Banner'
import Footer from './Footer'
import MobileNavPopup from './MobileNavPopup'
import css from './Layout.module.css'
import { UserContext } from '../context/useUserContext'

function Layout() {
   const [isMobileNavOpen, setMobileNavOpen] = useState(false)
   const [isDropdownOpen, setDropdownOpen] = useState(false)
   const location = useLocation()
   const { user } = useContext(UserContext)

   const isLoginPage = location.pathname === '/login'
   const shouldHideBanner =
      location.pathname.startsWith('/dashboard/') && user?.role !== 'visitor'

   if (isLoginPage) {
      return <Outlet />
   }

   return (
      <>
         <Navbar className={css.navbar} />
         {!shouldHideBanner && <Banner />}

         <div className={css.tabContainer}>
            <div
               className={css.hamburgerMenu}
               onClick={() => setMobileNavOpen(true)}
            >
               <span className={css.hamburgerIcon}>&#9776;</span>
               <span className={css.menuLabel}>Menu</span>
            </div>
            <nav className={css.tabNavigation}>
               {user?.role && user.role !== 'visitor' && (
                  <>
                     {user.role === 'CreatorUser' && (
                        <NavLink
                           to="/dashboard/creator/info"
                           className={({ isActive }) =>
                              isActive ? css.activeTab : css.tab
                           }
                        >
                           Dashboard Creator
                        </NavLink>
                     )}
                     {user.role === 'RegularUser' && (
                        <NavLink
                           to="/dashboard/regular"
                           className={({ isActive }) =>
                              isActive ? css.activeTab : css.tab
                           }
                        >
                           Dashboard Regular
                        </NavLink>
                     )}
                     {user.role === 'AdminUser' && (
                        <NavLink
                           to="/dashboard/admin"
                           className={({ isActive }) =>
                              isActive ? css.activeTab : css.tab
                           }
                        >
                           Dashboard Admin
                        </NavLink>
                     )}
                  </>
               )}

               <NavLink
                  to="/home"
                  className={({ isActive }) =>
                     isActive ? css.activeTab : css.tab
                  }
                  end
               >
                  Página Inicial
               </NavLink>
               <NavLink
                  to="/criadoresfinanceiros"
                  className={({ isActive }) =>
                     isActive ? css.activeTab : css.tab
                  }
               >
                  Educadores
               </NavLink>
               <NavLink
                  to="/eventosparcerias"
                  className={({ isActive }) =>
                     isActive ? css.activeTab : css.tab
                  }
               >
                  Eventos de Parcerias
               </NavLink>
               <NavLink
                  to="/glossario"
                  className={({ isActive }) =>
                     isActive ? css.activeTab : css.tab
                  }
               >
                  Glossário
               </NavLink>
               <NavLink
                  to="/noticias"
                  className={({ isActive }) =>
                     isActive ? css.activeTab : css.tab
                  }
               >
                  Notícias
               </NavLink>
               <NavLink
                  to="/livros"
                  className={({ isActive }) =>
                     isActive ? css.activeTab : css.tab
                  }
               >
                  Literacia Financeira
               </NavLink>

               {/* Dropdown para Conteúdos */}
               <div
                  className={css.dropdownContainer}
                  onMouseEnter={() => setDropdownOpen(true)}
                  onMouseLeave={() => setDropdownOpen(false)}
               >
                  <div className={css.tab}>Conteúdos ▼</div>
                  {isDropdownOpen && (
                     <div className={css.dropdownMenu}>
                        <NavLink to="/eventos" className={css.dropdownLink}>
                           Eventos
                        </NavLink>
                        <NavLink to="/brokers" className={css.dropdownLink}>
                           Corretoras
                        </NavLink>
                        <NavLink to="/website" className={css.dropdownLink}>
                           Websites e Apps
                        </NavLink>
                        <NavLink to="/cursos" className={css.dropdownLink}>
                           Cursos e Formações
                        </NavLink>
                        <NavLink to="/podcasts" className={css.dropdownLink}>
                           Podcasts
                        </NavLink>
                        <NavLink to="/community" className={css.dropdownLink}>
                           Comunidades
                        </NavLink>
                     </div>
                  )}
               </div>
            </nav>

            <MobileNavPopup
               isOpen={isMobileNavOpen}
               onClose={() => setMobileNavOpen(false)}
               user={user}
            />
         </div>

         <div className={css.mainContent}>
            <Outlet />
         </div>
         <Footer />
      </>
   )
}

export default Layout
