import React, { createContext, useContext, useEffect, useState } from 'react'
import { UserContext } from './useUserContext'

const DashboardContext = createContext()

export const useDashboard = () => useContext(DashboardContext)

export const DashboardProvider = ({ children, userData }) => {
   const [socialMediaLinks, setSocialMediaLinks] = useState([])
   const [newLink, setNewLink] = useState('')
   const [loading, setLoading] = useState(true)
   const { user } = useContext(UserContext)
   const [formData, setFormData] = useState({
      id: '',
      username: '',
      firstname: '',
      lastname: '',
      email: '',
      topics: [],
      role: '',
      bio: '',
      socialMediaLinks: [],
      profilePictureUrl: '',
      website: '', // Novo campo
      publicationFrequency: 'Ocasional', // Novo campo com valor padrão
   })

   console.log('user info', userData)

   useEffect(() => {
      const fetchCreatorProfile = async () => {
         try {
            const response = await fetch(
               `${process.env.REACT_APP_API_URL}users/creators/profile/${user.id}`
            )

            if (response.ok) {
               const data = await response.json()
               initializeFormData(data)
            } else {
               console.error(
                  'Erro ao buscar perfil do criador:',
                  await response.text()
               )
            }
         } catch (error) {
            console.error('Erro ao buscar perfil do criador:', error)
         } finally {
            setLoading(false)
         }
      }

      if (user && user.id) {
         fetchCreatorProfile()
      } else {
         setLoading(false)
      }
   }, [user])

   // Function to initialize form data safely
   const initializeFormData = (userData) => {
      if (!userData || userData.role === 'visitor') {
         // Configuração padrão para visitantes
         setFormData({
            id: userData?.id || '',
            username: userData?.username || '',
            firstname: userData?.firstname || '',
            lastname: userData?.lastname || '',
            email: userData?.email || '',
            topics: userData?.topics || [],
            role: userData?.role || '',
            bio: userData?.bio || '',
            socialMediaLinks: userData?.socialMediaLinks || [],
            profilePictureUrl: userData?.profilePictureUrl || '',
            website: userData?.website || '', // Novo campo
            publicationFrequency: userData?.publicationFrequency || 'Ocasional', // Novo campo
         })
         setSocialMediaLinks([])
         return
      }

      // Configuração para usuários autenticados
      setFormData({
         id: userData?.id || '',
         username: userData?.username || '',
         firstname: userData?.firstname || '',
         lastname: userData?.lastname || '',
         email: userData?.email || '',
         topics: userData?.topics || [],
         role: userData?.role || '',
         bio: userData?.bio || '',
         socialMediaLinks: userData?.socialMediaLinks || [],
         profilePictureUrl: userData?.profilePictureUrl || '',
         website: userData?.website || '', // Novo campo
         publicationFrequency: userData?.publicationFrequency || 'Ocasional', // Novo campo
      })

      setSocialMediaLinks(userData?.socialMediaLinks || [])
   }

   // Effect to initialize or update formData when userData changes
   useEffect(() => {
      initializeFormData(userData)
   }, [userData])

   const handleLinkChange = (e) => {
      // Visitantes não podem adicionar links
      if (formData.role !== 'visitor') {
         setNewLink(e.target.value)
      }
   }

   const handleAddLink = (link) => {
      // Visitantes não podem adicionar links
      if (formData.role !== 'visitor' && link) {
         setSocialMediaLinks((prevLinks) => [...prevLinks, link])
      }
   }

   const updateFormData = (data) => {
      // Somente usuários autenticados podem atualizar os dados
      if (formData.role !== 'visitor') {
         setFormData((prevFormData) => ({
            ...prevFormData,
            ...data,
         }))
      }
   }

   if (loading) {
      return <div>Carregando dados do utilizador...</div>
   }

   return (
      <DashboardContext.Provider
         value={{
            socialMediaLinks,
            setSocialMediaLinks,
            newLink,
            setNewLink,
            formData,
            setFormData,
            handleLinkChange,
            handleAddLink,
            updateFormData,
         }}
      >
         {children}
      </DashboardContext.Provider>
   )
}
