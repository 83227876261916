import React, { useContext, useEffect, useRef, useState } from 'react'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Dropdown } from 'primereact/dropdown'
import css from '../DashboardCreatorComponents.module.css'
import { useDashboard } from '../../../../../context/DashboardContext'
import { Button } from 'primereact/button'
import Select from 'react-select'
import { UserContext } from '../../../../../context/useUserContext'

const CreatorDetails = () => {
   const { formData, setFormData, updateFormData } = useDashboard()
   const { user } = useContext(UserContext)
   const fileInputRef = useRef(null) // Ref para o input de ficheiros
   const [topicsOptions, setTopicsOptions] = useState([])
   const [loading, setLoading] = useState(true)

   useEffect(() => {
      // Apenas buscar dados do criador se o utilizador não for um visitante
      if (user.role !== 'visitor') {
         const fetchTopics = async () => {
            try {
               const response = await fetch(
                  `${process.env.REACT_APP_API_URL}/users/topics`
               )
               if (response.ok) {
                  const data = await response.json()
                  setTopicsOptions(
                     data.map((topic) => ({ label: topic, value: topic }))
                  )
               } else {
                  console.error('Error fetching topics:', await response.text())
               }
            } catch (error) {
               console.error('Error fetching topics:', error)
            } finally {
               setLoading(false)
            }
         }

         fetchTopics()
      } else {
         setLoading(false)
      }
   }, [user.role])

   if (loading) {
      return <div>Carregando informações do perfil...</div>
   }

   if (user.role === 'visitor') {
      return <div>Acesso restrito. Faça login para visualizar os detalhes.</div>
   }
   // Pré-visualização da imagem ao carregar nova foto
   const handleFileChange = (event) => {
      const file = event.target.files[0]
      if (!file) return

      const objectUrl = URL.createObjectURL(file)
      console.log('Selected file:', file) // Verifica se o ficheiro foi selecionado corretamente
      console.log('Preview URL:', objectUrl) // Verifica se a URL da pré-visualização foi gerada

      setFormData((prev) => {
         const updatedFormData = {
            ...prev,
            profilePicturePreview: objectUrl,
            newImageFile: file, // Guardar o novo arquivo
         }
         console.log('Updated formData:', updatedFormData) // Verifica se o `formData` foi atualizado corretamente
         return updatedFormData
      })
   }

   // Salvar Alterações (inclui imagem e detalhes do perfil)
   const handleSave = async () => {
      try {
         let imageUrl = formData.profilePictureUrl

         // Fazer upload da imagem se uma nova foi selecionada
         if (formData.newImageFile) {
            const imageFormData = new FormData()
            imageFormData.append('image', formData.newImageFile)

            const uploadResponse = await fetch(
               `${process.env.REACT_APP_API_URL}users/uploadCreatorImage`,
               {
                  method: 'POST',
                  body: imageFormData,
               }
            )

            if (uploadResponse.ok) {
               const uploadData = await uploadResponse.json()
               console.log('Image uploaded successfully:', uploadData)
               imageUrl = uploadData.url
            } else {
               console.error(
                  'Failed to upload image:',
                  await uploadResponse.text()
               )
               return
            }
         }

         // Preparar os outros campos do formulário
         const payload = {
            id: formData.id,
            username: formData.username,
            firstname: formData.firstname,
            lastname: formData.lastname,
            email: formData.email,
            bio: formData.bio,
            role: formData.role,
            profilePictureUrl: imageUrl,
            topics: formData.topics || [],
            socialMediaLinks: formData.socialMediaLinks || [],
            website: formData.website || '',
            publicationFrequency: formData.publicationFrequency || 'Ocasional',
         }

         const updateResponse = await fetch(
            `${process.env.REACT_APP_API_URL}users/updateCreator/${formData.id}`,
            {
               method: 'PUT',
               headers: {
                  'Content-Type': 'application/json',
               },
               body: JSON.stringify(payload),
            }
         )

         if (updateResponse.ok) {
            const updatedData = await updateResponse.json()
            console.log('Profile updated successfully:', updatedData)
            setFormData(updatedData)
         } else {
            console.error(
               'Failed to update profile:',
               await updateResponse.text()
            )
         }
      } catch (error) {
         console.error('Error during save:', error)
      }
   }

   // Atualizar campos de texto no formulário
   const handleChange = (e) => {
      const { name, value } = e.target
      updateFormData({ [name]: value })
   }

   // Opções de frequência de publicação
   const publicationFrequencies = [
      { label: 'Diário', value: 'Diário' },
      { label: 'Semanal', value: 'Semanal' },
      { label: 'Mensal', value: 'Mensal' },
      { label: 'Ocasional', value: 'Ocasional' },
   ]
   const handleTopicsChange = (selectedOptions) => {
      const selectedValues = selectedOptions.map((option) => option.value) // Extrai apenas os valores como strings
      updateFormData({ topics: selectedValues })
   }

   return (
      <div className={css.profileContainer}>
         <div className={css.profileImageSection}>
            <input
               id="fileInput"
               type="file"
               ref={fileInputRef}
               onChange={handleFileChange}
               style={{ display: 'none' }}
            />
            {formData.profilePicturePreview ? (
               <img
                  src={formData.profilePicturePreview}
                  alt="Pré-visualização do Perfil"
                  className={css.imageProfile}
               />
            ) : formData.profilePictureUrl ? (
               <img
                  src={formData.profilePictureUrl}
                  alt="Imagem do Perfil"
                  className={css.imageProfile}
               />
            ) : (
               <div className={css.placeholder}>Sem imagem</div>
            )}
            <Button
               className={`${css.saveButton} ${css.primaryButton}`}
               onClick={() => fileInputRef.current.click()}
            >
               Trocar Imagem
            </Button>
         </div>

         <div className={css.detailsSection}>
            <div className={css.sectionTitle}>Informações Básicas</div>
            <div className={css.inputGroup}>
               <label className={css.labelCreator}>Username:</label>
               <InputText
                  type="text"
                  name="username"
                  value={formData.username || ''}
                  onChange={handleChange}
               />
            </div>

            <div className={css.inputGroup}>
               <label className={css.labelCreator}>Nome:</label>
               <InputText
                  type="text"
                  name="firstname"
                  value={formData.firstname || ''}
                  onChange={handleChange}
               />
            </div>

            <div className={css.inputGroup}>
               <label className={css.labelCreator}>Último Nome:</label>
               <InputText
                  type="text"
                  name="lastname"
                  value={formData.lastname || ''}
                  onChange={handleChange}
               />
            </div>

            <div className={css.inputGroup}>
               <label className={css.labelCreator}>Email:</label>
               <InputText
                  type="text"
                  name="email"
                  value={formData.email || ''}
                  onChange={handleChange}
               />
            </div>

            <div className={css.inputGroup}>
               <label className={css.labelCreator}>Bio:</label>
               <InputTextarea
                  rows={5}
                  name="bio"
                  value={formData.bio || ''}
                  onChange={handleChange}
                  placeholder="Adicione uma breve descrição sobre você"
               />
            </div>

            <div className={css.sectionTitle}>Detalhes Adicionais</div>
            <div className={css.inputGroup}>
               <label className={css.labelCreator}>Role:</label>
               <InputText
                  type="text"
                  name="role"
                  value={formData.role || ''}
                  onChange={handleChange}
                  disabled
               />
            </div>

            <div className={css.inputGroup}>
               <label className={css.labelCreator}>Topics:</label>
               <Select
                  isMulti
                  value={topicsOptions.filter((option) =>
                     (formData.topics || []).includes(option.value)
                  )} // Filtrar os tópicos selecionados
                  options={topicsOptions}
                  onChange={handleTopicsChange}
                  placeholder="Selecione tópicos"
               />
            </div>

            <div className={css.inputGroup}>
               <label className={css.labelCreator}>Website:</label>
               <InputText
                  type="text"
                  name="website"
                  value={formData.website || ''}
                  onChange={handleChange}
                  placeholder="Adicione seu website"
               />
            </div>

            <div className={css.inputGroup1}>
               <label className={css.labelCreator}>
                  Frequência de Publicação:{' '}
               </label>
               <Dropdown
                  name="publicationFrequency"
                  value={formData.publicationFrequency || ''}
                  options={publicationFrequencies}
                  onChange={(e) =>
                     updateFormData({ publicationFrequency: e.value })
                  }
                  placeholder="Selecione a frequência"
                  style={{ marginLeft: '10px' }}
               />
            </div>

            <div className={css.inputGroup}>
               <label className={css.labelCreator}>Aderiu:</label>
               <InputText
                  type="text"
                  name="createdAt"
                  value={
                     formData.createdAt
                        ? new Date(formData.createdAt).toLocaleDateString(
                             'pt-PT'
                          )
                        : ''
                  }
                  disabled
               />
            </div>

            <Button
               label="Guardar Alterações"
               className={css.saveButton}
               onClick={handleSave}
            />
         </div>
      </div>
   )
}

export default CreatorDetails
