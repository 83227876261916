import React, { useContext, useState, useEffect } from 'react'
import css from '../../pages/Layout.module.css'
import { UserContext } from '../../context/useUserContext'
import Avatar from './Avatar'
import useUserNavigation from '../../hooks/useUserNavigation'
import UserActions from './navBarComponents/UserActions'
import { Button } from 'primereact/button'

const Navbar = () => {
   const { user, logout } = useContext(UserContext)
   const { navigateToDashboard, navigateToLogin } = useUserNavigation()
   const [isNavbarVisible, setIsNavbarVisible] = useState(false)
   const [isAtTop, setIsAtTop] = useState(true)

   useEffect(() => {
      const handleScroll = () => {
         setIsAtTop(window.scrollY < 50)
      }

      window.addEventListener('scroll', handleScroll)
      return () => window.removeEventListener('scroll', handleScroll)
   }, [])

   const toggleNavbar = () => {
      setIsNavbarVisible(!isNavbarVisible)
      if (!isAtTop) {
         window.scrollTo(0, 0)
      }
   }

   const renderUserProfileLink = () => {
      if (user?.login) {
         const roleName =
            user.role === 'CreatorUser'
               ? 'Criador de Conteúdo'
               : user.role === 'AdminUser'
               ? 'Administrador'
               : 'Usuário Regular'

         return (
            <div className={css.flexCenter}>
               <Avatar imageUrl={user.profilePictureUrl} role={user.role} />
               <div className={css.userInfo}>
                  <div className="userAvatar">{user.username}</div>
                  <UserActions onEdit={navigateToDashboard} onLogout={logout} />
               </div>
            </div>
         )
      }

      return (
         <div className={`${css.flexCenter} ${css.userInfo}`}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
               <Avatar imageUrl={null} role="visitor" />
               <div className="userAvatar">Visitante</div>
            </div>
            <Button
               label="Login"
               onClick={navigateToLogin}
               className="p-button-success"
               size="small"
               style={{ marginLeft: '15px' }}
            />
         </div>
      )
   }

   return (
      <div className={css.navbarContainer}>
         <Button
            icon={isAtTop ? 'pi pi-bars' : 'pi pi-arrow-up'}
            onClick={toggleNavbar}
            className={css.menuButton}
            aria-label={isAtTop ? 'Toggle Menu' : 'Go to Top'}
         />
         {isNavbarVisible && (
            <div className={`${css.paddedContainer} ${css.navbarVisible}`}>
               <div className={css.navContent}>{renderUserProfileLink()}</div>
            </div>
         )}
      </div>
   )
}

export default Navbar
